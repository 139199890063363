<template>
    <div>
        <CCard class="p-2">
            <form @submit.prevent="submit">	
                <CCardHeader>
					<CRow style="color: #1352a1;">
						<CCol lg="8">
							<h5 v-if="!editMode">New Customer</h5>
							<h5 v-else>Update Customer</h5>
						</CCol>
				     	<CCol lg="4">
				      		<div style="text-align: right">
				      			<CButton size="sm" class="btn btn-ghost-dark" @click="backToTable()"> Cancel</CButton> &nbsp; 
					      		<CButton class="px-3"  size="sm" color="info" type="submit"><i class="fa fa-save"></i> Save</CButton>
				      		</div>
						</CCol>
					</CRow>
				</CCardHeader>
				
            <CCardBody class="card-form">
                <CCard class="pl-3 pr-3">
                        
                    <CRow class="mt-3">
						<CCol lg="12">
							<strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;"> Customer Information
							</strong>
						</CCol>
						
					</CRow><br>
                       
                    <CRow>
                        <CCol lg="12" >
                            <label class="mb-1">
                                Customer Name <span class="text-danger">*</span>
                            </label>
                            <CInput
                            v-model="dataParams.customer_name"
                            required="true"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="6">
                            <label class="mb-1">
                                Contact Number <span class="text-danger">*</span>
                            </label>
                            <CInput
                            v-model="dataParams.contact_no"
                            required="true"
                            type="number"
                            />
                        </CCol>
                        <CCol lg="6">
                            <label class="mb-1">
                                Email Address <span class="text-danger">*</span>
                            </label>
                            <CInput
                            
                            type="email"
                            
                            v-model="dataParams.email"
                            required="true"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <label class="mb-1">
                                Address 1 <span class="text-danger">*</span>
                            </label>
                            <CInput		                  
                            v-model="dataParams.address_a"
                            required="true"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <label class="mb-1">
                                Address 2 
                            </label>
                            <CInput
                            v-model="dataParams.address_b" 
                            />
                        </CCol>
                    </CRow><br>
                    <CRow class="mb-2">
						<CCol lg="12">
							<strong class="p-1 mb-2 bg-secondary text-dark" 
									style="font-size:12px; border-radius: 5px;"> Other Information
							</strong>
						</CCol>
						
				    </CRow><br>
                       
                    <CRow>
                        <CCol lg="6">
                            <label class="mb-1">
                                Contact Person <span class="text-danger">*</span>
                            </label>
                            <CInput
                            
                            
                            v-model="dataParams.contact_person"
                            required="true"
                            />
                        </CCol>
                        
                        <CCol lg="6">
                            
                            <div class="form-group"> 
                                <label class="mb-1">
                                    Depot
                                </label>
                                <v-select 
                                    label="setting_name"
                                    @input="depotChanged($event)"
                                    :options="depotList.data"
                                    :value="depot"
                                > 
                                </v-select>
                            </div>
                        
                   </CCol>
                       
                    </CRow>
                    <CRow>
                        <CCol lg="6">
                            <label class="mb-1">
                                TIN
                            </label>
                            <CInput
                            v-model="dataParams.tin" 
                            />
                        </CCol>
                        <CCol lg="6">
                            <label class="mb-1">
                                Account Code
                            </label>
                            <CInput
                            
                            v-model="dataParams.account_code" 
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <div class="form-group"> 
                            <label class="mb-1">Rate Type</label>
                            <v-select 
                                label="setting_name" 
                                :options="[
                                    {
                                        id: 'special',
                                        setting_name: 'Special'
                                    },
                                    {
                                        id: 'standard',
                                        setting_name: 'Standard'
                                    }
                                ]"
                                :reduce="item => item.id"
                                v-model="dataParams.rate_type"
                                placeholder="-Select-"
                            > 
                            </v-select>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                        <label>
                                Status <span class="text-danger">*</span>
                            </label>
                            <CInputRadioGroup  
                                :options="[
                                    { 
                                        value: 'active', 
                                        label: 'Active'
                                    },
                                    { 
                                        value: 'inactive', 
                                        label: 'Inactive'
                                    }
                                ]" 
                                :checked="dataParams.status"
                                @update:checked="updateStatus"
                                :inline="true" 
                            />
                        </CCol>
                    </CRow>
                </CCard>
             </CCardBody>

          
            </form>
        </CCard>
    </div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import moment from 'moment';
import '../../style.css';

export default {
	mounted(){ 
		
		
		this.getDepotList();

        if(this.$route.params.id != undefined){
			this.editMode = true;
		} 
		if(this.$route.params.id){
			this.getData();
		} 

	

	},
    data(){
		return{
			title:'',
			config,
			isLoading: false,
			depotName: "",
			depot: null,
            // depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id,
			dataParams: {
				customer_name: "",
				contact_no: "",
				email: "",
				address_a: "",
				address_b: "",
				contact_person: "",
				tin: "",
				account_code: "",
				rate_type: "",
				status: "active",
				depot_id: ""
			},
				// depot_id: JSON.parse(window.localStorage.getItem('user_data')).depot_id != null ? JSON.parse(window.localStorage.getItem('user_data')).depot_id : "",
			
			editMode: false,
			depotList: {
				data:[]
			}
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect},
    methods: {
        backToTable(){
	    	this.$router.push('/data/customer')
	    },
        depotChanged(ev) {
            console.log(ev)
			this.depot = ev;
			this.dataParams.depot_id = ev ? ev.id : "";

            
		},

	
	
		updatePage(data){
			this.currentPage = data;
			this.getData();
		
		}, 
       
        submit(){
	    	var ax = {};
	    	if(this.editMode){
	    		ax = axios.put(config.api_path+"/customer/"+this.$route.params.id, this.dataParams)
	    	}
	    	else{
	    		ax = axios.post(config.api_path+"/customer", this.dataParams)
	    	}
			this.$showLoading(true)
	      
	    	ax.then(response => {
				const { status } = response.data;

                this.$showLoading(false)
				
				if(status === 'duplicate') {
					Swal.fire({
						title: 'Customer name already exists.',
						text,
						icon: 'error', 
					})
						return;
				}

	    		var text = 'Customer successfully added!';
	    		if(this.editMode){
	    			text = 'Customer successfully updated!';
	    		}
	    		Swal.fire({
					title: 'Success!',
					text,
					icon: 'success', 
				})
                this.formModal = false
	    		this.editMode = false;
	    		this.dataParams = {
					customer_name: "",
					contact_no: "",
					email: "",
					address_a: "",
					address_b: "",
					contact_person: "",
					tin: "",
					account_code: "",
					rate_type: "",
					status: "active",
					depot_id : ""
				}
				
	    		this.$router.push('/data/view_customer/'+response.data.data.id)
	    	})
	    	.catch(e=>{
	    		this.$showLoading(false)
	    	})
	    },
        getData(){
            this.$showLoading(true)

			axios.get(config.api_path+"/customer/"+this.$route.params.id)
			.then(response=>{
                this.$showLoading(false)
				this.dataParams = response.data.data;
                this.depot = this.depotList.data.find(e => e.id == this.dataParams.depot_id);

			})
            .catch(err => {
			this.$showLoading(false)
		}) 

		}, 
        getDepotList(){

                axios.get(config.api_path+'/reference/settings-list',{
                params:{
                    setting_type:'depot'
                }
                })
                .then(response => {
                this.depotList = response.data;
                })

                },

	    
        updateDepot(data){
	    	this.dataParams.depot_id = data;
	    },

	    updateStatus(data){
	    	this.dataParams.status = data;
	    },

	
 	}
}
</script>
